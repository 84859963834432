import React from "react";
import "./footer.css";
import LogoSwitch from "../../assets/images/logoSwitch.png";
import LogoFacebook from "../../assets/images/social-fb.png";
import LogoInstagram from "../../assets/images/social-insta.png";
import LogoTwitter from "../../assets/images/social-tw.png";
import LogoD13 from "../../assets/images/D13.png";
import LogoIconeSwitch from "../../assets/images/logo_swuok.png";
import { Link} from "react-router-dom";

function Footer() {
  return (
    <footer className="border-top mt-5 vw-100 bg-white pt-5 px-5">
      <article className="d-flex flex-column align-items-center flex-lg-row">
        <section className="w-75 mt-3 d-flex justify-content-center justify-content-lg-start">
          <a href="https://www.chezswitch.fr/" target="_blank" rel="noreferrer">
            <img src={LogoSwitch} alt="logo 'chez switch'" />
          </a>
        </section>
        <section className="w-75 mt-3 d-flex justify-content-center">
          <a href="https://www.facebook.com/chezswitch/" target="_blank" rel="noreferrer">
            <img src={LogoFacebook} alt="icone facebook" />
          </a>
          <a href="https://www.instagram.com/chezswitch/?hl=fr" target="_blank" rel="noreferrer">
            <img src={LogoInstagram} alt="icone instagram" />
          </a>
          <a href="https://twitter.com/CHEZSWITCHfr" target="_blank" rel="noreferrer">
            <img src={LogoTwitter} alt="icone twitter" />
          </a>
        </section>
        <section className="w-75 mt-3 d-flex justify-content-center justify-content-lg-end">
          <a href="https://swu-coin.com/" target="_blank" rel="noreferrer">
            <img src={LogoIconeSwitch} alt="icone de 'chez switch'" />
          </a>
          <a href="https://www.d13.fr/" target="_blank" rel="noreferrer">
            <img src={LogoD13} alt="icone D13 studio" />
          </a>
        </section>
      </article>
      <article className="w-100 d-flex justify-content-between justify-content-sm-center mt-5">
        <section className="me-sm-3">
          <Link
            to="/mentions-legales"
            className="fontSizeLink text-decoration-none"
          >
            MENTIONS LÉGALES
          </Link>
        </section>
        <section className="my-1 border border-dark"></section>
        <section className="ms-sm-3">
          <Link
            to="/politique-confidentialite"
            className="fontSizeLink text-decoration-none"
          >
            POLITIQUE DE CONFIDENTIALITÉ
          </Link>
        </section>
      </article>
      <article className="w-100 d-flex justify-content-center mt-5">
        <section>
          <p id="fontSizeCopyRight" className="text-dark">
            © 2024 SWITCH - L'énergie est notre avenir, économisons-la !
          </p>
        </section>
      </article>
    </footer>
  );
}

export default Footer;
