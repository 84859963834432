import React, { useEffect, useState } from "react";
import "./bannerContact.css";
import iconeBulb from "../../assets/images/iconeBulb.svg";
import iconePhone from "../../assets/images/iconePhone.svg";
import logoSwitch from "../../assets/images/logoSwitchWhite.png";
import { motion, useAnimation } from 'framer-motion';

function BannerContact() {
  const [shouldMoveButtons, setShouldMoveButtons] = useState(false);

  const handleResize = () => {
    const viewportHeight = window.innerHeight;
    setShouldMoveButtons(viewportHeight < 800);
  };
  const containerButtonsControls = useAnimation();
  useEffect(() => {
    // Ajouter un écouteur d'événement pour gérer le redimensionnement de la fenêtre
    window.addEventListener("resize", handleResize);
    // Appeler handleResize initialement pour définir l'état initial en fonction de la hauteur de l'écran
    handleResize();

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      window.removeEventListener("resize", handleResize);
    };

  }, []);

  useEffect(() => {
    containerButtonsControls.start({ opacity: 1, y: 0 });
  }, [containerButtonsControls]);

  const handlePhoneClick = () => {
    // Redirige vers le numéro de téléphone
    window.location.href = "tel:0189471956";
  };

  const handleSwitchClick = () => {
    // Redirige vers le site internet chezSwitch
    window.open("https://www.chezswitch.fr/offre-energie/#/", "_blank");
  };

  return (
    <article
      id="containerBanner"
     className="row position-relative w-100 py-5 row">
      <section
        className="py-2 col-12 col-lg-6 m-0 d-flex flex-column align-items-center justify-content-center"
      >
        <h1 className="fw-semibold colorTitle text-center m-auto my-3 mt-2 pt-5 fs-1">
          Ouverture de Contrat Électricité
        </h1>
        <p className="fsDescription m-auto px-3">
          Si vous êtes à la recherche d'un fournisseur d'énergie pour votre
          domicile actuel ou pour votre futur logement lors d'un déménagement,
          Switch Energy est à vos côtés. En tant que fournisseur d'électricité,
          nous proposons des offres facilement compréhensibles et adaptées à vos
          besoins pour vous accompagner au quotidien.
        </p>
      </section>
      <motion.section
        id="containerButtons"
        className={`d-flex flex-column align-items-center justify-content-around z-3 col-12 col-lg-6 ${shouldMoveButtons ? "moveButtons" : ""
          }`}
        initial={{ opacity: 0, y: 300 }} // Propriétés initiales de l'animation
        animate={containerButtonsControls} // Utilise le contrôle d'animation créé précédemment
        transition={{ type: "spring", damping: 50, stiffness: 200 }} // Effet de ressort pour une animation smooth
      >
        <div
          onClick={handlePhoneClick}
          className="containerContact rounded-5 d-flex flex-column justify-content-around mb-3 align-items-center"
        >
          <article className="mt-3 ms-2">
            <img src={iconePhone} alt="icone d'un téléphone" />
          </article>
          <p className="fs-5 mt-sm-2 p-1 fw-bold text-center">
            Contactez-nous au <span className="fs-2">01 89 47 19 56</span>
          </p>
        </div>
        <div
          onClick={handleSwitchClick}
          className="containerContact rounded-5 d-flex flex-column justify-content-around align-items-center"
        >
          <article>
            <img src={iconeBulb} alt="icone d'une ampoule" />
          </article>
          <article className="d-flex flex-column align-items-center">
            <p className="fs-3 p-1 m-0 fw-bold text-center">Souscrire sur</p>
            <img id="logoSwitch" src={logoSwitch} alt="logo de chez SWITCH" />
          </article>
        </div>
      </motion.section>
    </article>
  );
}

export default BannerContact;
