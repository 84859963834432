import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import "./articleDescription.css";

function ArticleDescription(props) {
  const shouldAddReversContainer = props.revers === "yes";
  const controls = useAnimation();
  const sectionRef = useRef(null);
  const [isInView, setIsInView] = useState(false);


  const handleScroll = (entries) => {
    const [entry] = entries;
    setIsInView(entry.isIntersecting); // Met à jour l'état de isInView
    
  };
  useEffect(() => {
    const observer = new IntersectionObserver(handleScroll, {
      root: null,
      threshold: 0.2,
    });
  
    const currentSectionRef = sectionRef.current;
  
    if (currentSectionRef) {
      observer.observe(currentSectionRef);
    }
  
    return () => {
      if (currentSectionRef) {
        observer.unobserve(currentSectionRef);
      }
    };
  }, []);
  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    }
  }, [isInView, controls]);

  return (
    <motion.section
      ref={sectionRef}
      initial={{ opacity: 0, y: 300 }}
      animate={controls}
      transition={{ type: "spring", damping: 40, stiffness: 200 }}
      className="row w-100 m-0 mb-5 contentsArticles"
    >
      <div
        className={`col-12 col-lg-5 justify-content-center my-5 align-items-center ${
          shouldAddReversContainer ? "order-lg-last" : ""
        }`}
      >
        <p className="fs-2 m-0 mb-5 px-4 py-2 d-flex align-items-center text-center justify-content-center colorTitle titlesArticles">
          {props.title}
        </p>
        <p className="fs-5 m-0 p-1 py-2">{props.paragraph}</p>
      </div>
      <div
        className={`col-12 col-lg-5 d-flex justify-content-center contentsArticlesImages ${
          shouldAddReversContainer ? "order-lg-first" : ""
        } align-items-center`}
      >
        <img
          className="imageSize"
          src={props.image}
          alt="illustration d'énérgie renouvlable"
        />
      </div>
    </motion.section>
  );
}

export default ArticleDescription;
