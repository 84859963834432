import React, {useEffect} from "react";
import "./home.css";
import Header from "../../partials/Header/Header";
import BannerContact from "../../components/BannerContact/BannerContact";
import HeaderDescription from "../../components/HeaderDescription/HeaderDescription";
import ButtonForCall from "../../components/ButtonForCall/ButtonForCall";
import MainDescription from "../../components/MainDescription/MainDescription";
import Footer from "../../partials/Footer/Footer";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0); // Fait défiler la fenêtre en haut de la page
  }, []);

  return (
    <main id="bgColor" className="d-flex flex-column align-items-center vw-100 ">
      <Header />
      <BannerContact />
      <HeaderDescription />
      <MainDescription />

      <ButtonForCall class="buttonSize"/>
      <Footer/>
    </main>
  );
}

export default Home;
