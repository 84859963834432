import React from "react";
import "./headerDescription.css";
import switchEasy from "../../assets/images/Trv-21.webp";
import switchPower from "../../assets/images/pro-10.webp";
import eclair from "../../assets/images/iconeEclairJaune.png";

function HeaderDescription() {
  return (
    <article id="headerDescription" className="position-relative vw-100 mt-5">
      <section className="d-flex align-items-center justify-content-around">
        <img
          src={switchEasy}
          id="iconePower"
          className="iconeDescription position-absolute"
          alt="icone power de 'Chez SWITCH'"
        />
        <h3 className="m-0 fs-2 fw-bold px-5 text-center">
          Découvrez nos offres Électricité
        </h3>
        <img
          src={switchPower}
          id="iconeEasy"
          className="iconeDescription position-absolute"
          alt="icone easy de 'Chez SWITCH'"
        />
      </section>
      <section
        id="containerResume"
        className="row d-flex flex-column w-100 justify-content-around align-items-center mt-5 mx-0 px-5"
      >
        <img src={eclair} alt="icone d'éclair"/>
       
        <p className="text-center fs-2 col-md-10 fw-semibold">
            Switch Energy, l'électricité verte simplifiée.
        </p>
      </section>
    </article>
  );
}

export default HeaderDescription;
