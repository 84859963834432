import React from 'react';
import './buttonForCall.css';

function ButtonForCall(props) {
  const handlePhoneClick = () => {
    // Redirige vers le numéro de téléphone
    window.location.href = 'tel:0189471956';
  };

  // Vérifier si l'attribut "class" existe dans les props
  const buttonSizeClass = props.class ? props.class : '';

  return (
    <section
      onClick={handlePhoneClick}
      className={`border border-3 border-white contactNum rounded-4 d-flex align-items-center fw-bold justify-content-center ${buttonSizeClass}`}
    >
      <p className="m-0 py-1 text-white">Souscrire au 01 89 47 19 56</p>
    </section>
  );
}

export default ButtonForCall;
